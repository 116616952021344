<script>
import {UIcon, UInput, UOptionGroup, USelect, UTooltip, UFakeLoader} from 'uloc-vue'
import ErpSField from 'uloc-vue-plugin-erp/components/form/SimpleField'
import {find, create, update} from '@/domain/usuario/services/groups'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import UserMixin from "components/usuario/components/include/UserMixin"
import UserPermission from "components/usuario/components/include/Permission.vue"

export default {
  name: 'GroupForm',
  inject: ['userPage'],
  provide: function () {
    return {
      groupForm: this
    }
  },
  mixins: [UserMixin],
  props: {
    groupId: {required: false},
    full: {required: false, type: Boolean, default: false},
  },
  components: {
    ErpSField,
    UserPermission,
    // SlLoading,
    UInput,
    ERow,
    ECol,
    UFakeLoader
  },
  data() {
    return {
      loading: true,
      saving: false,
      bindPermissions: false,
      acl: this.userPage.acl,
      group: {
        id: null,
        name: null,
        acl: [],
        roles: [],
        active: true
      }
    }
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    if (this.groupId) {
      this.load(this.groupId)
    } else {
      this.loading = false
    }
  },
  destroyed() {
  },
  methods: {
    load(id) {
      this.loading = true
      find(id)
          .then(response => {
            this.group = response.data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            this.alertApiError(error)
          })
    },
    save() {
      this.$uloc.dialog({
        title: this.group.id ? 'Alterar grupo' : 'Criar grupo',
        message: this.group.id ? 'Você tem certeza que deseja alterar este grupo? Todos os usuários relacionados à ele serão afetados.' : 'Confirmar criação do novo perfil de acesso?',
        ok: 'Sim',
        cancel: 'Cancelar'
      }).then(() => {
        this.saving = true
        const data = JSON.parse(JSON.stringify(this.group))
        data.acl = this.$refs.permissions.modules.map(m => {
          m.components = m.components.filter(c => c.check)
          return m
        })
        let method
        if (data.id) {
          method = () => update(this.group.id, data, true)
        } else {
          method = () => create(data)
        }
        method()
            .then(response => {
              console.log(response.data)
              this.$nextTick(() => {
                this.saving = false
                this.dg()
                this.load(response.data.id)
              })
            })
            .catch(error => {
              this.saving = false
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>

<template>
  <div v-if="loading" class="m-t-xxl user-form-v2">
    <div class="box-v2">
      <h2><u-fake-loader width="40%" /></h2>
      <div class="flex content-stretch full-width">
        <u-fake-loader  width="80%" height="4px" style="min-width: 40%; flex: 1" />
        <u-fake-loader  width="40%" height="4px" style="min-width: 40%; flex: 1" />
        <u-fake-loader  width="40%" height="4px" style="min-width: 20%; flex: 1" />
      </div>
      <div class="flex content-stretch full-width">
        <u-fake-loader  width="80%" height="4px" style="min-width: 40%; flex: 1" />
        <u-fake-loader  width="40%" height="4px" style="min-width: 40%; flex: 1" />
        <u-fake-loader  width="40%" height="4px" style="min-width: 20%; flex: 1" />
      </div>
    </div>
  </div>
  <div v-else class="user-form-v2">
    <div class="profile-page wrapper-lg">
      <div class="box-v2">
        <h2 v-if="group.id">Perfil de Acesso</h2>
        <h2 v-else><strong>Novo Perfil</strong> de Acesso</h2>
        <e-row mr2 class="m-t">
          <e-col>
            <erp-s-field label="Nome do Perfil">
              <u-input autofocus inverted-light class="sl-select-v2 full-width" v-model="group.name"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <div class="m-t">

          <div v-if="!bindPermissions" class="m-t">
            <div class="group-perfil-subtitle">Permissões do perfil</div>
            <p class="m-t m-b-lg">Os usuários do ERP terão acesso exclusivamente às permissões atribuídas ao perfil selecionado. Além disso, você tem a opção de criar um novo perfil personalizado para ser utilizado no cadastro de novos usuários.</p>
            <user-permission ref="permissions" :entity="group" :acl="acl" :disabled="false" />
          </div>

          <e-row class="m-t-lg">
            <e-col class="flex justify-end">
              <u-btn label="Voltar" outline @click="$router.go(-1)" class="sl-btn bg-white text-green m-r"/>
              <u-btn :label="full ? 'Continuar' : 'Salvar alterações'" :loading="saving" @click="save" class="sl-btn bg-green text-white"/>
            </e-col>
          </e-row>
        </div>
      </div>
    </div>
  </div>

</template>

<style src="../assets/user-form.styl" lang="stylus"></style>
