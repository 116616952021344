<script>
import GroupForm from "components/usuario/v2/components/GroupForm.vue"
import Layout from "components/layout/Layout.vue"

export default {
  name: 'UsuarioGrupo',
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  components: {
    Layout,
    GroupForm
  },
  data() {
    return {}
  },
  watch: {
  },
  computed: {},
  mounted() {
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {},
  meta: {
    title: 'Grupo de Usuários',
    name: 'UsuarioGrupo'
  }
}
</script>

<template>
  <layout no-menu :folded="false" no-title hfull>
    <group-form :group-id="$route.params.id" full />
  </layout>
</template>

